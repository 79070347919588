<template>
  <div>
    <h4>Motif Decès</h4>
    
    <data-table
      :data-source="sortedActions"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-action-post-mortem"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout d'une Action Post Mortem"
      id="add-action-post-mortem"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingAction"
      >
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>

        <div class="form-group">
          <label for="add-code">Code</label>
          <input
            type="text"
            id="add-code"
            name="add-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="add-prix">Prix</label>
              <input
                id="add-prix"
                name="add-prix"
                type="text"
                class="form-control"
                v-model="prix"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="add-currency">Devise (monaie)</label>
              <select
                class="form-control"
                v-model="currency"
                v-select="{placeholder: 'Selectionnez la devise'}"
              >
                <option />
                <option
                  v-for="(c , i) in availableCurrencies"
                  :key="i"
                  :value="c.value"
                >
                  {{ c.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de l'espèce"
      id="update-action-post-mortem"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editingAction"
      >
        <div class="form-group">
          <label for="edit-libelle">Libelle</label>
          <input
            type="text"
            id="edit-libelle"
            name="edit-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="edit-code">Code</label>
          <input
            type="text"
            id="edit-code"
            name="edit-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="update-prix">Prix</label>
              <input
                id="update-prix"
                name="update-prix"
                type="text"
                class="form-control"
                v-model="prix"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="update-currency">Devise (monaie)</label>
              <select
                class="form-control"
                v-model="currency"
                v-select="{placeholder: 'Selectionnez la devise'}"
              >
                <option />
                <option
                  v-for="(c , i) in availableCurrencies"
                  :key="i"
                  :value="c.value"
                >
                  {{ c.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {ADD_ACTION_POST_MORTEM,  EDIT_ACTION_POST_MORTEM } from '../../../graphql/identification'
import DataTable from '../../../components/dataTable/local.vue'
import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
import {CURRENCIES} from '../../../constants/app'
const Actions = () => import('../../../components/admin/identification/postMortemAction.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            code: null,
            prix: null,
            currency: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
      selectedObject: {
            handler(){
                if(this.selectedObject !== null) {
                    this.libelle = this.selectedObject.libelle
                    this.code = this.selectedObject.code
                    this.prix = this.selectedObject.prix
                    this.currency = this.selectedObject.currency
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.code = null
        },
        addingAction(){
            let data = {
                libelle: this.libelle,
                code: this.code,
                prix: parseInt(this.prix),
                currency: this.currency
            }
            this.$apollo.mutate({
                mutation: ADD_ACTION_POST_MORTEM,
                variables: {
                    "action": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Action post mortem add successfully on uid ${d.addEspece}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editingAction(){
            let data = {
                libelle: this.libelle,
                code: this.code,
                prix: parseInt(this.prix),
                currency: this.currency
            }
            this.$apollo.mutate({
                mutation: EDIT_ACTION_POST_MORTEM,
                variables: {
                    "action": {
                        ...data
                    }, 
                    "uid": this.selectedObject.uid
                },
                update: () => {
                    console.log(`Action post mortem  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            actions: 'identification/actionsPostMortem',
            selectedObject: 'selectedObject'
        }),
        sortedActions(){
          return [...this.actions].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        availableCurrencies(){
                return CURRENCIES
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Code', name: 'code', type: TEXT_TYPE},
                {label: 'Prix', name: 'prix', type: TEXT_TYPE},
                {label: 'Devise', name: 'currency', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        }
    }
}
</script>

<style>

</style>